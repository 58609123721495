export const getProjects = () => [
  {
    year: 2024,
    projects: [
      {
        name: "API Sismos Chile",
        description: "",
        title_description: "project_12_description",
        isWebEnabled: true,
        url: "https://api.sebdev.cl/docs",
      },
      {
        name: "vue-wave",
        description: "",
        title_description: "project_11_description",
        isWebEnabled: true,
        url: "https://wave.sebdev.cl/",
      },
      {
        name: "py-github-svg-profile",
        description: "",
        title_description: "project_10_description",
        isWebEnabled: true,
        url: "https://github.com/Seb-RS/py-github-svg-profile",
      },
    ],
  },
  {
    year: 2023,
    projects: [
      {
        name: "VueMathLab",
        description: "",
        title_description: "project_9_description",
        isWebEnabled: true,
        url: "https://lab.sebdev.cl/",
      },
      {
        name: "Sismos Chile",
        description: "",
        title_description: "project_8_description",
        isWebEnabled: true,
        url: "https://sismos.sebdev.cl/",
      },
      {
        name: "Servidores de BattleBit",
        description: "",
        title_description: "project_7_description",
        isWebEnabled: false,
        url: null,
      },
      {
        name: "Habbostalk",
        description: "",
        title_description: "project_6_description",
        isWebEnabled: false,
        url: null,
      },
      {
        name: "Simple Snake Game",
        description: "",
        title_description: "project_5_description",
        isWebEnabled: true,
        url: "https://snake.sebdev.cl/",
      },
    ],
  },
  {
    year: 2022,
    projects: [
      {
        name: "LexManager",
        description: "",
        title_description: "project_4_description",
        isWebEnabled: false,
        url: null,
      },
      {
        name: "Ricoy.me",
        description: "",
        title_description: "project_3_description",
        isWebEnabled: false,
        url: null,
      },
    ],
  },
  {
    year: 2021,
    projects: [
      {
        name: "Innovación HSE",
        description: "",
        title_description: "project_2_description",
        isWebEnabled: false,
        url: null,
      },
    ],
  },
  {
    year: 2018,
    projects: [
      {
        name: "Eternum.gg",
        description: "",
        title_description: "project_1_description",
        isWebEnabled: false,
        url: null,
      },
    ],
  },
];