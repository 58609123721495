<template>
  <div
    id="custom-cursor"
    v-if="cursorX !== 0 && cursorY !== 0"
    :style="{ top: cursorY + 'px', left: cursorX + 'px' }"
  >
    <div
      class="w-5 h-5 duration-300"
      :class="{
        'text-black/40 dark:text-white/80': normalCursor,
        'text-black/40 dark:text-white/80 scale-150 -rotate-180': !normalCursor,
        'text-black/40 dark:text-white/80': currentCrosshairId === null || currentCrosshairId === 1,
        'text-cyan-400': currentCrosshairId === 2,
      }"

      :style=" {opacity: opacity + '%', scale: opacity / 100}"
    >
      <svg
        v-if="currentCrosshairId === null"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 1024 1024"
      >
        <circle cx="512" cy="512" r="256" fill-rule="evenodd" />
      </svg>
      <svg
        v-if="currentCrosshairId === 1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        :style="{ width: '100%', height: '100%' }"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        viewBox="0 0 16 16"
      >
        <path d="M8 5.25v-3m0 11.5v-3M10.75 8h3M2.25 8h3" />
        <circle cx="8" cy="8" r="6.25" />
        <circle
          cx="8"
          cy="8"
          r="0.25"
          :class="{ 'opacity-0': normalCursor, 'opacity-100': !normalCursor }"
        />
      </svg>
      <svg
        v-if="currentCrosshairId === 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7 7h2v2H7V7zm0-7h2v5H7V0zm0 11h2v5H7v-5zm4-4h5v2h-5V7zM0 7h5v2H0V7z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      cursorX: 0,
      cursorY: 0,
      opacity: 0,
    };
  },
  computed: {
    ...mapState(["normalCursor"]),
    ...mapState(["currentCrosshairId"]),
  },
  methods: {
    updateCursorPosition(e) {
      if (this.$store.state.isMobile) return;

      this.cursorX = e.clientX;
      this.cursorY = e.clientY;

      this.$store.commit("setCursor", {
        pageX: e.pageX,
        pageY: e.pageY,
      });
    },
  },
  mounted() {
    this.opacity = 100;
    window.addEventListener("mousemove", this.updateCursorPosition);
  },
  beforeUnmount() {
      window.removeEventListener("mousemove", this.updateCursorPosition);
      window.removeEventListener("mousemove", this.updateCursorPosition);
  },
  watch: {
    currentCrosshairId() {
      if (!this.isMobile()) {
        this.opacity = 50;
        setTimeout(() => {
          this.opacity = 100;
        }, 100);
      }
    },
    "$store.state.status"() {
      this.$store.state.status ? this.opacity = 100 : this.opacity = 0;
    },
  },
};
</script>
<style scoped>
#custom-cursor {
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
  filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 1));
}

.dark #custom-cursor {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));
}
</style>
