<template>
    <div class="p-0.5 relative card w-full h-full" :style="border" ref="el">
        <div class="bg-[#fafafa] dark:bg-[#121113] relative rounded-xl flex flex-col w-full h-full">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            el: null,
        }
    },
    mounted() {
        this.el = this.$refs.el instanceof HTMLElement ? this.$refs.el : null;
    },
    computed: {
        border() {
            if (this.$store.state.status) {
                return {
                    '--x': `${this.$store.state.cursor.pageX - (this.$el?.offsetLeft ?? 0)}px`,
                    '--y': `${this.$store.state.cursor.pageY - (this.$el?.offsetTop ?? 0)}px`,
                };
            }
            return {};
        },
    },
}
</script>

<style scoped>
.card::before {
    content: '';
    position: absolute;
    border-radius: 0.75rem;
    inset: 0;
    background: radial-gradient(200px circle at var(--x) var(--y), #22d3ee, transparent);
}
</style>
