import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            status: true,
            normalCursor: true,
            currentSongId: null,
            currentCrosshairId: null,
            scrollInProgress: false,
            currentSongModal: false,
            isMenuActive: false,
            currentSection: 'home',
            isMobile: false,
            cursor: {},
            themes: [
                { id: 1, name: "Wild Ones", status: 0, path: "/assets/themes/WildOnesTrack.mp3" },
                { id: 2, name: "Counter Strike 1.6", status: 0, path: "/assets/themes/CounterStrike1.6Track.mp3" },
            ],
            songInfo: null,
            currentLightDark: localStorage.getItem('theme') === 'dark' ? true : false || false,
            currentLang: localStorage.getItem('lang') === 'en' ? true : false || false,
        };
    },
    mutations: {
        setNormalCursor(state, value) {
            state.normalCursor = value;
        },
        setCurrentSongId(state, songId) {
            state.currentSongId = songId;
            state.currentCrosshairId = songId;
        },
        setCurrentSection(state, value) {
            state.currentSection = value;
        },
        setThemeStatus(state, { themeId, status }) {
            const theme = state.themes.find(theme => theme.id === themeId);
            if (theme) {
                theme.status = status;
            }
        },
        setScrollInProgress(state, value) {
            state.scrollInProgress = value;
        },
        setSongInfo(state, songInfo) {
            state.songInfo = songInfo;
        },
        setCurrentSongModal(state, value) {
            state.currentSongModal = value;
        },
        setMenuActive(state, value) {
            state.isMenuActive = value;
        },
        setCurrentLightDark(state, value) {
            state.currentLightDark = value;
            document.documentElement.classList.toggle("dark", value);
            localStorage.setItem('theme', value ? 'dark' : 'light');
        },
        setCurrentLang(state, value) {
            state.currentLang = value;
            localStorage.setItem('lang', value ? 'en' : 'es');
        },
        setCursor(state, cursor) {
            state.cursor = cursor;
        }
    },
    getters: {
        currentSong(state) {
            return state.themes.find(theme => theme.id === state.currentSongId);
        },
    },
});
